import { nanoid } from "nanoid";
import { API_URL } from "../config/settings";
import { Headers } from "./Headers";

// export const uploadFile = (file) =>
//   S3FileUpload.uploadFile(
//     new File([file], file?.name || nanoid(), {
//       type: file?.type || "",
//       lastModified: file?.lastModified || new Date(),
//     }),
//     s3Credentials
//   )

export const uploadFile = async (file) => {
  let filename = file.name;
  filename = filename.split("%").join("").split(" ").join("");
  filename = `admin/${nanoid()}_${filename}`;
  const response = await fetch(`${API_URL}/uploads/admin-panel/presigned-url`, {
    method: "POST",
    headers: Headers.get_auth(),
    body: JSON.stringify({ path: filename, mimeType: file?.type ?? "" }),
  });
  if (!response.ok) throw new Error("Грешка");
  const {
    result: { presignedUrl, url },
  } = await response.json();
  const response2 = await fetch(presignedUrl, {
    method: "PUT",
    body: file,
    headers: { "Content-Type": file?.type ?? "" },
  });
  if (!response2.ok) throw new Error("Грешка");
  return { name: filename, location: url };
};

export const uploadFiles = async (files, special, dir) => await Promise.all([...files].map((file) => uploadFile(file, special, dir)));

// export const uploadFiles = (files) => {
//   const promises = [];
//   Array.from(files).forEach((f) =>
//     promises.push(
//       S3FileUpload.uploadFile(
//         new File([f], f?.name || nanoid(), {
//           type: f?.type,
//           lastModified: f?.lastModified || new Date(),
//         }),
//         s3Credentials
//       )
//     )
//   );
//   return Promise.all(promises);
// };
